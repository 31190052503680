@import '../../styles/index.scss';
 
.question {
    color: $gray1;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    .header  {
        flex: 1;
        margin: 10px;
        padding-top: 20px;

        h2 {
            font-size: 24px;
            font-weight: 500;
        }

    }
    .body {
        flex: 3;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        padding-bottom: 20px;
        #answerText {
            width: 90%;
            height: 80%;
            background-color: rgb(233, 235, 237);
            border-radius: 5px;
            padding: 10px;
            font: 1em sans-serif;
           
        }
        .checkBoxWrap {
            display: flex;
            justify-content: flex-start;
            width: 90%;
            padding-bottom: 20px;
            flex-wrap: wrap;
            .checkItem {
                width: 200px;
                padding: 10px 0;
                input {
                    margin-right: 5px;
                }
            }
        }
    }
}