@import '../../styles/index.scss';

.final {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;

    .thank {
        font-size: 30px;
        font-weight: 600;
        flex: 1;
        padding: 20px 10px;
        color: $color1;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .rateGoogle {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        align-content: space-between;
        padding: 10px;
        .title {
            font-size: 18px;
            font-weight: 500;
            margin-bottom: 10px;
        }
        img {
            width: 120px;
            height: auto;
        }
    }
    .ratingAgain {
        padding: 5px 10px;
        background-color:  $color1;
        color: #fff;
        margin-top: 30px;
        cursor: pointer;
        border-radius: 5px;
        position: absolute;
        bottom: 5px;
        right: 5px
    }
}