@import '../../styles/index.scss';

.widgetItem {
    height: 90%;
    width: 90%;
    background-color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 5px;
    // @include breakpoint($sm) {
    //     height: 80%;
    //     width: 80%;
    // }
    // @include breakpoint($md) {
    //     height: 80%;
    //     width: 80%;
    // }
    // @include breakpoint($lg) {
    //     height: 80%;
    //     width: 80%;
    // }
    &.left {
        left: -150vw;

    }
    &.right {
        left: 150vw;
 
    }
    &.center {
        transition: all 400ms ease;

    }
    .main {
        height: 80%;
    };
    .footer {
        height: 15%;
        display: flex;
        justify-content: center;
        align-items:flex-end;
        .nextButton {
            background-color: $color1;
            padding: 10px 25px;
            color: #fff;
            border-radius: 5px;
            transition: all 300ms ease;
            &:hover {
                -webkit-box-shadow: 1px 3px 10px 2px #8B8B8B; 
                box-shadow: 1px 3px 10px 2px #8B8B8B;

            }
        }
    };

}
