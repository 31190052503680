.navbar {
    height: 50px;
    border-bottom: 0.5px solid #999;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 14px;
    color: #555;

    .wrapper {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }
    
    .inputSearch {
        border: 0.5px solid #999;
        display: flex;
        align-items: center;
        margin-left: 10px;
        border-radius: 5px;
        padding: 5px;
        background-color: transparent;
        input {
            border: none;
            outline: none;
            background: transparent;
            ::placeholder {
                font-size: 12px;
            }

        }
    }
    .items {
        display: flex;
        .item {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 20px;
            position: relative;;

            .icon {
                font-size: 24px;
                color: green;
            }
            .avatar {
                border-radius: 50%;
            }
            .counter {
                width: 15px;
                height: 15px;
                background-color: rgb(226, 80, 80);
                color:white ;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;

                font-size: 10px;
                position: absolute;
                top: 3px;
                left: 12px;
                


            }
        }
    }
}