.time {
    display: flex;
}
.listContainer {
    // background-color: aqua;
    flex: 3;
    display: flex;
    flex-direction: column;
    .tableWrapper {
        margin: 20px;
        .navTime {
            display: flex;
            align-items: center;
            padding: 20px 0px;

            button {
                margin: 5px;
                padding: 5px 10px;
                background-color: gray;
                color: #fff;
                border-radius: 3px;
                cursor: pointer;

                &.active {
                    background-color: rgb(103, 150, 120);

                }
            }
        }
    }
    
   }