@import '../../styles/index.scss';

.login {
    background-color: rgba(213, 213, 213, 0.5);
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center;
    .loginWrap {
        background-color: #fff;
        border-radius: 10px;
        width: 400px;
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .title {
            font-size: 20px;
            font-weight: 500px;
            padding: 20px 0px;

        }
        .loginForm {
            padding: 20px;
            width: 80%;
            form {
                display: flex;
                flex-direction: column;
                width: 100%;
                label {
                    font-size: 14px;
                    font-weight: 500;
                }
                input {
                    background-color: rgba(213, 213, 213, 0.5);
                    margin-top: 10px;
                    margin-bottom: 30px;
                    outline: none;
                    padding: 8px;
                    border-radius: 5px;
                    
                    &:focus {
                        border: 0.5px solid gray;
                    }
                    &.loginSubmit {
                        background-color: $color1;
                        color: white;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}