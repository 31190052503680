.admin {
    display: flex;
    
 
    .homeContainer {
     // background-color: aqua;
     flex: 3;
     display: flex;
     flex-direction: column;
    }
    .adminWidgets, .charts { 
       display: flex;
       padding: 20px;
       gap: 20px
    }
    .chartContainer {
       -webkit-box-shadow: 2px 4px 10px 1px #B9B9B9; 
     box-shadow: 2px 4px 10px 1px #B9B9B9;
     border-radius: 10px;
     margin: 10px;
     padding: 10px;
     display: flex;
     flex-wrap: wrap;
     .chartBox {
      // height: 300px;
     }

    }
 }
 