@import '../../styles/index.scss';


.home {
    display: flex;
    position: relative;
    height: 100vh;
    justify-content: center;
    align-items: center;

    @include breakpoint($sm) {
    }
    @include breakpoint($md) {
    }
    .langs {
        position: absolute;
        top: 5px;
        right: 20px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .langItem {
            margin: 0 10px;
            cursor: pointer;
            &:hover, &.active {
                color: $color1;
            }
        }
    }
    .locationMenu {
        position: absolute;
        top: 5px;
        left: 10px;
        display: flex;
        .dropWrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            span {
                visibility: hidden;
                display: none;
                cursor: pointer;
                &:hover {
                    background-color: beige;
                }
                &.active {
                    visibility:visible;
                    display: block;

                }
            }
        }
        &.show span {
            visibility:visible;
            display: block;

        }
    }

}
