@import '../../styles/index.scss';


.widgets {
    height: 90vh;
    width: 90vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    background-color: $color1;
    position: relative;
    -webkit-box-shadow: 1px 3px 10px 2px #8B8B8B; 
box-shadow: 1px 3px 10px 2px #8B8B8B;
border-radius: 10px;

    @include breakpoint($sm) {
        height: 80vh;
        width: 90vw;
    }
    @include breakpoint($md) {
        height: 70vh;
        width: 600px;
    }
    @include breakpoint($lg) {
        height: 70vh;
        width: 800px;
    }
    .top {
        flex: 8;
        position: relative;
        }
    .bottom {
        flex: 1;
        display: flex;
        justify-content: space-between;
        margin: 0 10px;
        .next, .back {
            background-color: transparent;
            color: rgb(202, 202, 202);
            padding: 5px;
            border-radius: 5px;
            height: 50px;
            transition: all 300ms ease;
            &:hover {
                background-color: white;
            color: $color1;
            }

        }
        
    };
}