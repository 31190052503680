.notFound {
    height: 100vh;
    background-image: linear-gradient( 174.2deg,  rgba(255,244,228,1) 7.1%, rgba(240,246,238,1) 67.4% );
    display: flex;
    justify-content: center;
    align-items: center;
    .wrapper {
        width: 500px;
        .title {
            font-size: 30px;
            font-weight: 600;
            padding-bottom: 20px;
        }
        p {
            font-size: 24px;
        }
        a {
            color: rgb(14, 76, 14);
            font-size: 20px;
            font-weight: bold;
        }
    }
}