@import '../../styles/index.scss';

.satisfied {
    height: 100%;
    display: flex;
    align-items: stretch;
    flex-direction: column;
    .iconWrap {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-around;
        background-color:  rgb(211, 211, 211);
        margin-top: 20px;
        padding: 30px 0px;
        .iconSas {
            height: 100px;
        }
    }
    .icon {
        transition: all 300ms ease;
        border-radius: 50%;
        cursor: pointer;
        &:nth-child(1) {
            color: #f55823;
        }
        &:nth-child(2) {
            color: #f5a123;
        }
        &:nth-child(3) {
            color: #eef523;
        }
        &:nth-child(4) {
            color: $color1;
        }
        &.active, &:hover {
            background-color: #fff;
        
        }
        
    }
    .bottom {
        flex: 1;
        display: flex;
        justify-content: center;
        min-height: 100px;
        align-items: center;
        .title {
            font-size: 25px;
            font-weight: 500;
        }
    }


}

