

.settings {
    display: flex;
}
.settingContainer {
    // background-color: aqua;
    flex: 3;
    display: flex;
    flex-direction: column;

    .settingWrap {
        margin-top: 30px ;
        display: flex;
        flex-direction: column;
        align-items: center;
        h2 {
            font-size: 30px;
            margin: 20px 0;
        }
        .settingBox {
            width: 500px;
            .downloadButton {
                padding: 10px 25px;
                background-color: green;
                color: #fff;
                margin-left: 20px;
            }
        }
    }

   }