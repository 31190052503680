@import '../../styles/index.scss';
 
.likely {
    color: $gray1;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    .header  {
        flex: 2;
        margin: 10px;
        padding-top: 20px;

        h2 {
            font-size: 24px;
            font-weight: 500;
        }
        padding-bottom: 20px;
        @include breakpoint($sm) {
            padding-bottom: 20px;
        }
    }
    .body {
        flex: 3;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 20px;
        .title {
            font-size: 12px;
            margin-bottom: 5px;
            width: 90%;
            display: flex;
            justify-content: space-between;
        }
        .scoreWrap {
            display: flex;
            width: 90%;
            justify-content: center;
            .score {
                flex-basis: 15px;
                flex-grow: 1;
                text-align: center;
                background-color: bisque;
                border: 1px solid $color1;
                padding: 5px;
                font-size: 16px;
                font-weight: 600;
                cursor: pointer;
                transition: all 200ms ease;

                &:hover, &.active {
                    background-color: rgb(50, 49, 49);
                    color: #fff;
                }
            }
        }
    }
}