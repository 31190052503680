@import '../../styles/index.scss';

.rating {
    height: 100%;
    display: flex;
    align-items: stretch;
    flex-direction: column;
    .iconWrap {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-around;
        background-color:  rgb(211, 211, 211);
        margin-top: 20px;
        padding: 30px 0px;
    }
    .icon {
        color: #fff;
        transition: all 300ms ease;
        &.active, &.hover {
            color: #F5A623;
        }
    }
    .bottom {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: 100px;
        align-items: center;
        .title {
            font-size: 25px;
            font-weight: 500;
        }
        .afterSend {
            p {
                color: #f55f23;
                margin-bottom: 10px;
            }
            button {
                padding: 5px 10px;
                cursor: pointer;
                background-color: $color1;
                border-radius: 3px;
            }
        }
    }


}

